@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Reddit+Mono:wght@200..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (-webkit-touch-callout: none) {
  .ios-bg-fix {
    background-attachment: scroll;
  }
}
